import React from 'react';
import styles from '../Program.module.scss';

export const Marker = ({ children }: { children: string }) => {
  return (
    <em className={styles.marker} dir="auto">
      {children}
    </em>
  );
};
